import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Link from "../link"
import { TRANSLATIONS } from "../../../utils/translations"
import ArrowSvg from "../../svg/arrow"

const SinglePostElement = styled.article`
  position: relative;
  max-width: 45rem;
  width: 100%;

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    max-width: 65rem;
  }
`

const PostCategories = styled.ul`
  display: flex;
  font-size: 1.8rem;
  text-transform: uppercase;

  li {
    display: flex;
    align-items: center;
    position: relative;
    letter-spacing: 0.36rem;
    margin-right: 5rem;

    &:last-child {
      margin-right: 0;

      &:after {
        display: none;
      }
    }

    &:after {
      position: absolute;
      top: 50%;
      right: -2.5rem;
      width: 0.2rem;
      height: 0.2rem;
      background: ${props => props.theme.colors.text};
      content: "";
    }
  }
`

const PostTitle = styled.h3`
  font-size: 3.5rem;
  font-family: "darker_grotesquebold", sans-serif;
  line-height: 4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    font-size: 4.8rem;
    line-height: 5.5rem;
  }

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    font-size: 6.8rem;
    line-height: 8rem;
  }
`

const PostNav = styled.div`
  display: flex;
`

const PostDate = styled.div`
  font-size: 1.8rem;
  line-height: 3.6rem;
  letter-spacing: 0.36rem;
  margin-left: auto;
`

const ReadMore = styled.p`
  font-size: 2rem;
  font-family: "darker_grotesquebold", sans-serif;

  svg {
    position: relative;
    top: 1rem;
    width: 2.3rem;
    margin-left: 2rem;
    transform: rotate(90deg);

    path {
      fill: ${props => props.theme.colors.main};
    }
  }
`

const StyledOverlayLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const SinglePost = ({ post, blogPageSlug }) => {
  if (!post) return

  const postDate = new Date(post.createdAt)
  const postDay = postDate.getUTCDay()
  const postMonth = postDate.getMonth()
  const postYear = postDate.getFullYear()

  return (
    <SinglePostElement>
      {post?.categories && (
        <PostCategories>
          {post.categories.map((category, index) => (
            <li key={index}>{category}</li>
          ))}
        </PostCategories>
      )}
      <PostTitle>
        <Link url={`/${blogPageSlug}/${post.slug}/`}>{post.title}</Link>
      </PostTitle>

      <PostNav>
        <ReadMore>
          {TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].readMore}{" "}
          <ArrowSvg />
        </ReadMore>
        <PostDate>{`${postDay}.${postMonth}.${postYear}`}</PostDate>
      </PostNav>
      <StyledOverlayLink
        url={`/${blogPageSlug}/${post.slug}/`}
        ariaLabel={post.title}
        rel="nofollow"
      />
    </SinglePostElement>
  )
}

SinglePost.propTypes = {
  post: PropTypes.object,
  blogPageSlug: PropTypes.string,
}

export default SinglePost
