import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import SinglePost from "./single-post"
import { MainContext } from "../../../context/main-context"
import { TRANSLATIONS } from "../../../utils/translations"
import { gsap } from "gsap"

const PostsList = styled.div`
  display: flex;
  flex-direction: column;

  article {
    margin-bottom: 10rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${props => props.theme.devices.desktop}) {
    article {
      margin-bottom: 0;

      &:nth-of-type(2n) {
        margin-left: auto;
      }
    }
  }
`

const Categories = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 7rem;
`

const SingleCategory = styled.li`
  white-space: nowrap;
  margin-right: 8rem;
  margin-bottom: 5rem;

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    margin-right: 15rem;
  }
`

const FilterButton = styled.button`
  position: relative;
  font-size: 2.8rem;
  background: none;
  border: none;
  cursor: pointer;
  padding-bottom: 0.5rem;
  transition: 800ms ${props => props.theme.easing};
  border-bottom: 0.3rem solid #fff;

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    font-size: 3.6rem;
  }

  ${props =>
    props.$isActive &&
    `
    border-bottom-color:#000000;
  `}

  span {
    position: absolute;
    top: -1.5rem;
    right: -3.7rem;
    width: 3.7rem;
    height: 3.7rem;
    background: #000;
    font-size: 2rem;
    line-height: 3rem;
    font-family: "darker_grotesquebold", sans-serif;
    color: #fff;
    border-radius: 50%;
    padding-top: 0.3rem;
  }
`

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10rem;
`

const PaginationButton = styled.button`
  width: 4.2rem;
  height: 4.2rem;
  color: ${props => props.theme.colors.text};
  font-size: 2.2rem;
  background: none;
  border: 0.1rem solid ${props => props.theme.colors.text};
  border-radius: 50%;
  margin: 0 0.8rem;
  padding-bottom: 0.2rem;
  will-change: background, color;
  cursor: pointer;

  ${props =>
    props.$isActive &&
    ` 
    background: ${props.theme.colors.text};
    color: ${props.theme.colors.invertedText};
    cursor: default;
  `}
`

const PostsListElement = ({
  categories,
  posts,
  showFilters = false,
  blogPageSlug,
}) => {
  const { setCurrentPage } = useContext(MainContext)
  const [filteredPosts, setFilteredPosts] = useState(posts)
  const [filtersIndex, setFiltersIndex] = useState(0)
  const [paginationPage, setPaginationPage] = useState(0)
  const [paginatedPosts, setPaginatedPosts] = useState(filteredPosts)
  const [pagesNumber, setPagesNumber] = useState(
    Math.round(filteredPosts.length / 10),
  )
  const filterPosts = (filter, index) => {
    setFiltersIndex(index)

    if (index === 0) {
      setCurrentPage(null)
      setPagesNumber(Math.round(posts.length / 10))

      return setFilteredPosts(posts)
    }

    const filteredArray = posts.filter(post =>
      post.node.categories?.includes(filter),
    )

    setCurrentPage(filter)
    setFilteredPosts(filteredArray)
    setPagesNumber(Math.round(filteredArray.length / 10))
    gsap.to(window, {
      duration: 1,
      scrollTo: { y: `#posts-wrapper`, offsetY: 100 },
    })
  }

  const changePage = index => {
    if (index === paginationPage) return

    setPaginationPage(index)

    gsap.to(window, {
      duration: 1,
      scrollTo: { y: `#posts-wrapper`, offsetY: 100 },
    })
  }

  useEffect(() => {
    setPaginatedPosts(
      filteredPosts.slice(paginationPage * 10, (paginationPage + 1) * 10),
    )
  }, [paginationPage, filteredPosts, setPaginatedPosts])

  return (
    <div id="posts-wrapper">
      {showFilters && categories && (
        <Categories>
          <SingleCategory>
            <FilterButton
              onClick={() => filterPosts(null, 0)}
              $isActive={0 === filtersIndex}
            >
              {TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].all}{" "}
              <span>{posts.length}</span>
            </FilterButton>
          </SingleCategory>
          {categories.map((category, index) => (
            <SingleCategory key={index}>
              <FilterButton
                onClick={() => filterPosts(category.name, index + 1)}
                $isActive={index + 1 === filtersIndex}
              >
                {category.name} <span>{category.number}</span>
              </FilterButton>
            </SingleCategory>
          ))}
        </Categories>
      )}
      {paginatedPosts && (
        <PostsList>
          {paginatedPosts.map((post, index) => (
            <SinglePost
              key={index}
              post={post.node ? post.node : post}
              blogPageSlug={blogPageSlug}
            />
          ))}
        </PostsList>
      )}

      {pagesNumber > 1 && (
        <PaginationContainer>
          {[...Array(pagesNumber).keys()].map((pageNumber, index) => {
            return (
              <PaginationButton
                key={index}
                onClick={() => changePage(pageNumber)}
                $isActive={pageNumber === paginationPage}
              >
                {pageNumber + 1}
              </PaginationButton>
            )
          })}
        </PaginationContainer>
      )}
    </div>
  )
}

PostsListElement.propTypes = {
  posts: PropTypes.array,
  showFilters: PropTypes.bool,
  categories: PropTypes.array,
  blogPageSlug: PropTypes.string,
}

export default PostsListElement
